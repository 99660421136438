<template>
  <v-container
    id="widgets"
    fluid
    tag="section"
    class="pt-0"
  >
    <!-- Таблица Производительности START -->
    <base-material-card
      color="success"
      inline
      class="px-5 py-3"
      filter
    >
      <template #heading>
        <div class="text-h3 font-weight-light">
          <v-icon>mdi-file-document-box-multiple-outline</v-icon> Производительность
        </div>
      </template>
      <template #after-heading>
        <v-select
          v-model="DossiersOfficesSelect"
          class="pr-2"
          label="Подразделение:"
          :items="DossiersOffices"
          item-text="dossiersofficetitle"
          item-value="dossierofficeid"
          @change="updateData"
        />
        <v-menu
          ref="menuFrom"
          v-model="menuFrom"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template #activator="{ on }">
            <v-text-field
              v-model="dateFromF"
              persistent-hint
              @blur="parseDate(dateFromF)"
              v-on="on"
            >
              <span
                slot="prepend"
                class="flex text-h4 font-weight-light"
              >с
              </span>
            </v-text-field>
          </template>
          <v-date-picker
            v-model="dateFrom"
            no-title
            locale="ru-ru"
            @input="updateDay()"
          />
        </v-menu>
        <v-btn
          color="info"
          default
          rounded
          class="mt-2"
          :loading="loading"
          :disabled="isChange.length === 0"
          @click="saveChanges"
        >
          <v-icon class="mr-2">
            mdi-file-plus-outline
          </v-icon>
          сохранить
        </v-btn>
      </template>
      <v-row>
        <v-col class="pl-0">
          <v-progress-linear
            v-if="loadingData"
            indeterminate
            color="yellow darken-2"
            class="pa-2 my-6"
          />
          <v-grid
            v-else
            :source="rows"
            :columns="headersPerform"
            :column-types="plugin"
            style="height: 400px"
            row-class="myRowClass"
            @beforeEdit="checkedit"
          />
        </v-col>
      </v-row>
    </base-material-card>
    <!-- Таблица Отчетов END-->
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'
  import VGrid from '@revolist/vue-datagrid'
  import NumberColumnType from '@revolist/revogrid-column-numeral' // import library

  export default {
    name: 'Simplefoods',
    components: {
      VGrid,
    },
    data () {
      return {
        loadingData: false,
        loading: false,
        templist: [],
        datedate: new Date(),
        weekSelect: false,
        rowsSnap: [],
        rows: [],
        confirmAction: {
          show: false,
          actionName: null,
        },
        plugin: { numeric: new NumberColumnType('00') },
        DossiersOffices: [],
        DossiersOfficesSelect: null,
        timeSheetsStatesSel: {},
        updateIndex: null,
        TallysPage: 1,
        PerformItemsPerPage: 10,
        delIndex: null,
        delpopup: false,
        formHasErrors: false,
        normHours: 12,
        editedItem: {},
        dialogEditEmp: false,
        empSelectDialog: false,
        workshopsListSelect: null,
        workshopsList: [],
        selectedTallyLoading: true,
        addEmpPopup: {
          show: false,
          type: '',
        },
        selectedPerform: null,
        addpopup: false,
        dateFrom: null,
        dateTo: null,
        dateFromF: null,
        dateToF: null,
        menuFrom: false,
        menu1: false,
        menu2: false,
        date: new Date().toISOString().substr(0, 10),
        dateFormatted: this.formatDate(new Date().toISOString().substr(0, 10)),
        tableLoading: false,
        selectedVac: [],
        showFilter: false,
        Performs: [],
        PerformsGroup: {},
      }
    },
    computed: {
    ...mapGetters({
    }),
    isChange () {
      const a = this.rows
      const b = this.rowsSnap
      if (a === b) return 'true1'
      if (a == null || b == null) return 'false2'
      if (a.length !== b.length) return false
      // If you don't care about the order of the elements inside
      // the array, you should sort both arrays here.
      // Please note that calling sort on an array will modify that array.
      // you might want to clone your array first.
      const oddRows = {}
      const oddCells = []
      // const oddDays = []
      for (let i = 0; i < a.length; ++i) {
        if (JSON.stringify(a[i]) !== JSON.stringify(b[i])) {
          oddRows[a[i].wsname] = [a[i], b[i]]
        }
      }
      for (const row in oddRows) {
        for (const col in oddRows[row][0]) {
          if (oddRows[row][0][col] !== oddRows[row][1][col]) {
            const oddCell = this.findCellId(row, col)
            oddCell.pvalue = oddRows[row][0][col]
            if (!!oddCell.pid && oddCell.ptype === 1) {
              oddCell.ptype = 2
              oddCell.pid = null
            }
            oddCells.push(oddCell)
          }
        }
      }
      return oddCells
    },
    headersPerform () {
      const PerformsHeaders = this.PerformsGroup[Object.keys(this.PerformsGroup)[0]] || []
      const headerArr = []
      if (PerformsHeaders.length > 0) {
        headerArr.push({
          name: 'Отделы',
          prop: 'wsname',
          pin: 'colPinStart',
          size: 120,
          readonly: true,
        })
        for (const x of PerformsHeaders) {
          headerArr.push({
            name: x.datestr.split('-')[2],
            prop: x.datedate.toString(),
            columnType: 'numeric',
            size: 55,
            cellProperties: ({ prop, model, data, column }) => {
              return {
                style: {
                  'padding-right': '5px',
                  'text-align': 'right',
                },
              }
            },
          })
        }
      }
      return headerArr
    },
    errorMessages () {
      return this.checkNormTime ? '' : 'Некорректное время, необходимо внести примечание'
    },
    },
    mounted () {
      this.loadingData = true
      const week = this.dates(new Date())
      console.log('weekMount', week)
      const d = new Date()
      d.setDate(1)
      const dt = new Date()
      dt.setMonth(dt.getMonth() + 1)
      dt.setDate(0)
      this.dateTo = new Date(dt).toISOString().substr(0, 10)
      this.dateToF = this.formatDate(this.dateTo)
      this.dateFrom = new Date(d).toISOString().substr(0, 10)
      this.dateFromF = this.formatDate(this.dateFrom)
      this.$store.dispatch('getDossiersOffices').then((x) => {
        this.DossiersOffices = x
        this.DossiersOfficesSelect = x[0].dossierofficeid
        this.$store.dispatch('getSPFperformance', {
          dateTo: this.dateTo,
          dateFrom: this.dateFrom,
          dossierofficeid: this.DossiersOfficesSelect,
          ptype: 2,
        }).then((resp) => {
          this.Performs = resp
          let perf = this.groupResponse(resp, 'ptype')
          this.templist = JSON.parse(JSON.stringify(perf))
          perf['2'].forEach((x, i) => {
            if (x.pvalue) {
              perf['1'][i].pvalue = x.pvalue
              perf['1'][i].ptype = x.ptype
            }
          })
          perf = perf['1']
          this.PerformsGroup = this.groupResponse(perf, 'wstitle')
          this.rows = []
          for (const x in this.PerformsGroup) {
            const result = {
              wsname: x,
              myRowClass: 'fit',
            }
            this.PerformsGroup[x].forEach((i) => {
              result[i.datedate.toString()] = i.pvalue
            })
            this.rows.push(result)
          }
        }).then(() => {
          this.rowsSnap = JSON.parse(JSON.stringify(this.rows))
          this.loadingData = false
        }).catch((e) => {
          console.log(e)
        })
      })
    },
    methods: {
      updateDay () {
        this.menuFrom = false
        this.updateData()
      },
      updateData () {
        this.loadingData = true
        this.Performs = []
        this.cantUpdate = false
        const d = new Date(this.dateFrom)
        d.setDate(1)
        const dt = new Date(this.dateFrom)
        dt.setMonth(dt.getMonth() + 1)
        dt.setDate(0)
        const dateTo = new Date(dt).toISOString().substr(0, 10)
        const dateFrom = new Date(d).toISOString().substr(0, 10)
        this.dateFrom = dateFrom
        this.dateFromF = this.formatDate(this.dateFrom)
        console.log(`date from: ${dateFrom} date to:${dateTo}`)
        this.$store.dispatch('getSPFperformance', {
          dateTo: dateTo,
          dateFrom: dateFrom,
          dossierofficeid: this.DossiersOfficesSelect,
          ptype: 2,
        }).then((resp) => {
          this.Performs = resp
          let perf = this.groupResponse(resp, 'ptype')
          this.templist = JSON.parse(JSON.stringify(perf))
          perf['2'].forEach((x, i) => {
            if (x.pvalue) {
              perf['1'][i].pvalue = x.pvalue
              perf['1'][i].ptype = x.ptype
            }
          })
          perf = perf['1']
          this.PerformsGroup = this.groupResponse(perf, 'wstitle')
          this.rows = []
          for (const x in this.PerformsGroup) {
            const result = {
              wsname: x,
              myRowClass: 'fit',
            }
            this.PerformsGroup[x].forEach((i) => {
              result[i.datedate.toString()] = i.pvalue
            })
            this.rows.push(result)
          }
        }).then(() => {
          this.rowsSnap = JSON.parse(JSON.stringify(this.rows))
          this.loadingData = false
        }).catch((e) => {
          console.log(e)
        })
      },
      parseDate (date) {
        if (!date) return null
        const [day, month, year] = date.split('-')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      },
      findCellId (row, col) {
        const fulldata = this.PerformsGroup[row].find(x => x.datedate == col)
        return fulldata
      },
      makeChangeList () {
        const changesCells = []
        console.log(changesCells)
      },
      dates (current) {
        const week = []
        // Starting Monday not Sunday
        current.setDate((current.getDate() - current.getDay() + 1))
        for (let i = 0; i < 7; i++) {
          week.push(
            new Date(current).toISOString().split('T')[0]
          )
          current.setDate(current.getDate() + 1)
        }
        return week
      },
      async saveChanges () {
        this.loading = true
        try {
          await this.$store.dispatch('peformPerDayChange', { pload: JSON.stringify(this.isChange), type: 2 })
          // const x = await this.$store.dispatch('getDossiersOffices')
          // this.DossiersOffices = x
          // this.DossiersOfficesSelect = x[0].dossierofficeid
          const resp = await this.$store.dispatch('getSPFperformance', {
            dateTo: this.dateTo,
            dateFrom: this.dateFrom,
            dossierofficeid: this.DossiersOfficesSelect,
            ptype: 2,
          })
          this.Performs = resp
          let perf = this.groupResponse(resp, 'ptype')
          console.log(perf)
          this.templist = JSON.parse(JSON.stringify(perf))
          console.log(perf['2'])
          perf['2'].forEach((x, i) => {
            if (x.pvalue) {
              console.log('aa', perf['1'][i].pvalue)
              console.log('aa', perf['1'][i].ptype)
              console.log('aa', x.pvalue)
              console.log('aa', x.ptype)
              perf['1'][i].pid = x.pid
              perf['1'][i].pvalue = x.pvalue
              perf['1'][i].ptype = x.ptype
            }
          })
          perf = perf['1']
          console.log(perf)
          this.PerformsGroup = this.groupResponse(perf, 'wstitle')
          this.rows = []
          for (const x in this.PerformsGroup) {
            const result = {
              wsname: x,
              myRowClass: 'fit',
            }
            this.PerformsGroup[x].forEach((i) => {
              result[i.datedate.toString()] = i.pvalue
            })
            this.rows.push(result)
          }
          this.rowsSnap = JSON.parse(JSON.stringify(this.rows))
          this.loading = false
        } catch (e) {
          console.log(e)
          this.loading = false
        }
      },
      // saveChanges () {
      //   this.loading = true
      //   this.$store.dispatch('peformPerDayChange', { pload: JSON.stringify(this.isChange), type: 2 }).then((resp) => {
      //     this.$store.dispatch('getDossiersOffices').then((x) => {
      //       this.DossiersOffices = x
      //       this.DossiersOfficesSelect = x[0].dossierofficeid
      //       this.$store.dispatch('getSPFperformance', {
      //         dateTo: this.dateTo,
      //         dateFrom: this.dateFrom,
      //         dossierofficeid: this.DossiersOfficesSelect,
      //         ptype: 2,
      //       }).then((resp) => {
      //         this.Performs = resp
      //         let perf = this.groupResponse(resp, 'ptype')
      //         console.log(perf)
      //         this.templist = JSON.parse(JSON.stringify(perf))
      //         console.log(perf['2'])
      //         perf['2'].forEach((x, i) => {
      //           if (x.pvalue) {
      //             console.log('aa', perf['1'][i].pvalue)
      //             console.log('aa', perf['1'][i].ptype)
      //             console.log('aa', x.pvalue)
      //             console.log('aa', x.ptype)
      //             perf['1'][i].pid = x.pid
      //             perf['1'][i].pvalue = x.pvalue
      //             perf['1'][i].ptype = x.ptype
      //           }
      //         })
      //         perf = perf['1']
      //         console.log(perf)
      //         this.PerformsGroup = this.groupResponse(perf, 'wstitle')
      //         // this.PerformsGroup = { ...this.PerformsGroup, ...{ [x]: perf[x].reduce((hash, obj) => ({ ...hash, [obj[key]]: (hash[obj[key]] || []).concat(obj) }), {}) } }
      //         // for (const x in this.PerformsGroup['1']) {this.PerformsGroup['1'].forEach((x, i) => {
      //         //   if (this.PerformsGroup['2'][i].pvalue) {
      //         //     this.PerformsGroup['1'][i].pvalue = this.PerformsGroup['2'][i].pvalue
      //         //     this.PerformsGroup['1'][i].ptype = this.PerformsGroup['2'][i].ptype
      //         //   }
      //         // })
      //         this.rows = []
      //         for (const x in this.PerformsGroup) {
      //           const result = {
      //             wsname: x,
      //             myRowClass: 'fit',
      //           }
      //           this.PerformsGroup[x].forEach((i) => {
      //             result[i.datedate.toString()] = i.pvalue
      //           })
      //           this.rows.push(result)
      //         }
      //       }).then(() => {
      //         this.rowsSnap = JSON.parse(JSON.stringify(this.rows))
      //         this.loading = false
      //       }).catch((e) => {
      //         console.log(e)
      //         this.loading = false
      //       })
      //     }).catch((e) => {
      //       console.log(e)
      //       this.loading = false
      //     })
      //   }).catch((e) => {
      //     console.log(e)
      //     this.loading = false
      //   })
      // },
      checkUpdate () {
        console.log('update')
      },
      checkedit (val) {
        val.detail.val = val.detail.val.replace(/\D/g, '')
        if (val.detail.val === '') {
          val.detail.val = null
        }
      },
      formatDate (date) {
        if (!date) return null
        const [year, month, day] = date.split('-')
        return `${day}-${month}-${year}`
      },
      groupResponse (resp, key) {
        return resp.reduce((hash, obj) => ({ ...hash, [obj[key]]: (hash[obj[key]] || []).concat(obj) }), {})
      },
    },
  }
</script>

<style>
revogr-viewport-scroll.col {
  padding: 0;
}
.checkit{
  background-color: salmon;
}
.row.fit {
  display: block;
  margin: 0;
  text-align: center;
}
</style>
